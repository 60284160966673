$('document').ready(function () {
    $('.ressources-foyer-tooltip-content').tooltipster({
        maxWidth: '300',
        position: 'top',
        contentAsHTML: true,
        content: "Indiquez le Revenu Fiscal de Référence (RFR) de votre foyer. <br /> <br />Retrouvez le RFR sur votre dernier avis d’imposition disponible: <br /><br /><img src=\"https://cdn.v2.primesenergie.fr/images/preca/revenu-fiscal.png\" style=\" width: 100%;\" /> <br/><br/>Vous devez renseigner le nombre de personnes dans le foyer ainsi que le code postal pour que les tranches de revenus s'affichent"
    });

    $('.CEF_INITIAL-tooltip-content').tooltipster({
        maxWidth: '300',
        position: 'top',
        contentAsHTML: true,
        content: "Audit incitatif : CEF selon les 3 usages. Audit réglementaire : CEF selon les 5 usages. Elle doit être rapportée à la surface habitable (S<sub>hab</sub>), et non SHON."
    });

    $('.CEF_PROJET-tooltip-content').tooltipster({
        maxWidth: '300',
        position: 'top',
        contentAsHTML: true,
        content: "Audit incitatif : CEF selon les 3 usages. Audit réglementaire : CEF selon les 5 usages. Elle doit être rapportée à la surface habitable (S<sub>hab</sub>), et non SHON."
    });

    $('.ERR_50-tooltip-content').tooltipster({
        maxWidth: '300',
        position: 'top',
        contentAsHTML: true,
        content: "En cas du changement d’un équipement de chauffage, le taux ENR doit obligatoirement être mentionné sur le rapport de l’audit énergétique."
    });

    $('.ash-tooltip-content').tooltipster({
        maxWidth: '200',
        position: 'top',
        contentAsHTML: true,
        content: "Cliquez sur l'icone PDF dans vos documents pour télécharger votre Attestation sur l'honneur.<br/><br/><img src=\"https://cdn.v2.primesenergie.fr/images/personal-space/dossier-action-list.png\" style=\" width: 100%;\" />"
    });
    $('.tooltipster').tooltipster({
        maxWidth: '300',
        position: 'top'
    });
    $('.nb-hab-foyer-tooltip-content').tooltipster({
        maxWidth: '300',
        position: 'top',
        contentAsHTML: true,
        content: "Le nombre de personnes du ménage fiscal* est obtenu par cumuc des personnes inscrites sur les déclarations de revenus qui le composent." +
            "<br/><br/>On adopte le terme de personne et non celui d'habitant pour marquer le fait que toute personne rattachée fiscalement à un ménage ne vit pas forcément au sein de celui-ci: elle peut habiter ailleurs." +
            "<br/><br/><i>Par exemple: un étudiant rattaché fiscalement à ses parents tout en occupant un logement indépendant, sera comptabilisé dans le nombre de personne composant le ménage.</i>" +
            "<br/><br/>*Un ménage fiscal est un ménage constitué par le regroupement des foyers fiscaux répertoriés dans un même logement." +
            "<br/><br/><i>Par exemple: un couple de concubins, où chacun remplit sa propre déclaration de revenus constitue un seul ménage fiscal parce qu'ils sont répertoriés dans le même logement, même s'ils constituent deux contribuables distincts au sens de l'administration fiscale.</i>"
    });
    $('.taxhousehold-tooltip-content').tooltipster({
        maxWidth: '300',
        position: 'top',
        contentAsHTML: true,
        content: "Le terme foyer fiscal désigne l'ensemble des personnes inscrites sur une même déclaration de revenus.<br/>Il peut y avoir plusieurs foyers fiscaux dans un seul ménage." +
            "<br/><br/><i>Par exemple: un couple non marié où chacun remplit sa propre déclaration de revenus compte pour deux foyers fiscaux.</i>"
    });
    $('.pro-siret-tooltip-content').tooltipster({
        maxWidth: '300',
        position: 'top',
        contentAsHTML: true,
        content: "Le SIRET doit figurer sur la facture, il est<br/> composé de 14 chiffres"
    });
    $('.pro-infos-tooltip-content').tooltipster({
        maxWidth: '300',
        position: 'top',
        contentAsHTML: true,
        content: "L'attestation sur l'honneur devra être signée<br/>par le professionnel installateur, merci de<br/>renseigner ses coordonnées."
    });
    $('.pro-soust-raitant-siret-tooltip-content').tooltipster({
        maxWidth: '300',
        position: 'top',
        contentAsHTML: true,
        content: "Le SIRET doit figurer sur la facture, il est<br/> composé de 14 chiffres"
    });
    function sisocTooltip(eligibleId) {
        if (eligibleId == 106) { ///bar-en-101
            $('.SISOC-tooltip-content').show();
        } else {
            $('.SISOC-tooltip-content').hide();
        }
        if (eligibleId == 343){//bar-th-177
            $('.RRC_ERR-tooltip-content').show();
        } else {
            $('.RRC_ERR-tooltip-content').hide();
        }
    }
    sisocTooltip($('select[name$="[eligible_work]"]').val());
    $('select[name$="[eligible_work]"]').on('change', function(){
        sisocTooltip(this.value);
    });
    $('.SISOC-tooltip-content').tooltipster({
        maxWidth: '300',
        position: 'top',
        contentAsHTML: true,
        content: "Pour être éligible à la prime CEE, les parois isolées doivent séparer une surface chauffée d'une surface non chauffée."
    });
    $('.RRC_ERR-tooltip-content').tooltipster({
        maxWidth: '300',
        position: 'top',
        contentAsHTML: true,
        content: "dans le cas où le raccordement à un réseau de chaleur existant est impossible, l'attestation de l'opérateur de chaleur devrait être transmise."
    });
});
